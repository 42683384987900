<template>
  <div class="view pa24">
    <template v-if="!drafts">
      订单状态：
      <el-radio
        v-model="radioValue"
        v-for="(item, index) in radioArr"
        :key="index"
        class="mr10"
        style="margin: 0"
        :label="item.value"
        border
        size="medium"
        >{{ item.lable }}</el-radio
      >
      <el-button type="primary" @click="getGoodsInfoPCShop"
        >搜索</el-button
      >
      <el-button
        type="primary"
        style="float: right"
        @click="$router.push({ path: '/marketing/group/addAssemble' })"
        >新增</el-button
      >
    </template>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
<!--        <el-table-column-->
<!--          prop="assembleId"-->
<!--          align="center"-->
<!--          label="序号"-->
<!--          show-overflow-tooltip-->
<!--        />-->
        <el-table-column prop="goodsName" align="center" label="团购商品" />
<!--        <el-table-column prop="peopleNum" align="center" label="成团购人数" />-->
<!--        <el-table-column prop="stockNum" align="center" label="商品库存">-->
<!--          <template slot="header">-->
<!--            商品库存-->
<!--            <el-tooltip-->
<!--              class="item"-->
<!--              effect="dark"-->
<!--              content="展示该商品下的所有库存之和"-->
<!--              placement="top"-->
<!--            >-->
<!--              <i style="font-size: 16px" class="el-icon-question" />-->
<!--            </el-tooltip>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column
          prop="minAssemblePrice"
          align="center"
          label="拼团金额"
        >
          <template slot="header">
            拼团金额
            <el-tooltip
              class="item"
              effect="dark"
              content="展示该商品下所有规格最低的拼团金额"
              placement="top"
            >
              <i style="font-size: 16px" class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            {{ (scope.row.minAssemblePrice / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="minPrice" align="center" label="单独购买金额">
          <template slot="header">
            单独购买金额
            <el-tooltip
              class="item"
              effect="dark"
              content="展示该商品下所有规格最低的原价"
              placement="top"
            >
              <i style="font-size: 16px" class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            {{ (scope.row.minPrice / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="openRegiment"
          align="center"
          label="单账号最多可开团次数"
        />
        <el-table-column
          prop="joinRegiment"
          align="center"
          label="单账号最多可参团数"
        />
        <el-table-column
          prop="maxPurchase"
          align="center"
          label="单账号最多可购买数"
        />
        <el-table-column prop="status" align="center" label="状态" v-if="!drafts">
          <template slot-scope="scope">
             {{ getProState(scope.row.status )}}
            <!-- {{ scope.row.status == 1 ? "进行中" : "已结束" }} -->
          </template>
        </el-table-column>
        <el-table-column
          prop="joiningTime"
          align="center"
          label="拼团时间"
          width="200"
        >
          <template slot-scope="scope">
            <div>起 ：{{ scope.row.assemStartTime }}</div>
            <div>止 ：{{ scope.row.assemEndTime }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              :disabled="!hasPermi"
              @click="
                $router.push({
                  path: `/marketing/group/addAssemble?id=${scope.row.assembleId}&drafts=1`,
                })
              "
              >编辑</el-button
            >
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="delGoodsAssembleInfo(scope.row.assembleId)"
              icon="el-icon-info"
              icon-color="red"
              title="是否删除该条拼团数据，删除后不可恢复。">
                <el-button
                  class="ml10"
                  slot="reference"
                  type="text"
                  style="color: #fd634e"
                  :disabled="!hasPermi"
                >
                  删除
                </el-button>
            </customPopconfirm>
            <customPopconfirm
              v-if="drafts"
              onConfirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="publish(scope.row.goodsId, scope.row.assembleId)"
              icon="el-icon-info"
              icon-color="red"
              title="确认发布该拼团活动吗？">
              <el-button class="ml10" type="text" slot="reference" :disabled="!hasPermi">
                发布
              </el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  selectGoodsAssembleDraftsPage,
  getGoodsInfoPCShop,
  releaseGoodsAssemble,
  delGoodsAssembleInfo,
} from "@/api/joinGroup";
import { getDataTimeSec } from "@/utils";
export default {
  name: "list",
  components: {
    commonTable,
    customPopconfirm
  },
  props: {
    drafts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasPermi: true,
      radioValue: "3",
      radioArr: [
        { lable: "全部", value: "3" },
        { lable: "进行中", value: "1" },
        { lable: "已结束", value: "2" },
      ],
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
    };
  },
  created() {
    this.getGoodsInfoPCShop();
    if(JSON.parse(localStorage.getItem('isSourceAdmin'))) {
      this.hasPermi = false
    }
  },
  computed: {
    setGetDataTimeSec() {
      return (val) => getDataTimeSec(val);
    },
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getGoodsInfoPCShop();
    },
    async getGoodsInfoPCShop() {
      let data = {
        assembleType: this.drafts ? 0 : 1,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        requestType: this.radioValue,
      };
      data['isAssemble'] = 1
      try {
        this.loading = true;
        const result = await getGoodsInfoPCShop(data);
        console.log(result)
        this.loading = false;
        this.tableData = result.data.pageInfo.list;
        this.total = result.data.pageInfo.list.length;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    /**@method 删除 */
    async delGoodsAssembleInfo(assembleId) {
      await delGoodsAssembleInfo({ assembleId });
      this.getGoodsInfoPCShop();
    },
    /**@method 发布 */
    async publish(goodsId, assembleId) {
      try {
        await releaseGoodsAssemble({ goodsId, assembleId });
        this.getGoodsInfoPCShop();
      } catch (error) {
        console.log(error);
      }
    },
    getProState(status){
      let res = '--'
      if(status === 1){
        res = "进行中"
      }else if(status === 2) {
        res = "已结束"
      }else if(status === 0) {
        res = "未开始"
      }
      return res
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
